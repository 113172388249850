import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { PageComponent } from './pages/page/page.component';
import { SponsorPageComponent } from './pages/sponsor-page/sponsor-page.component';
import { NewsComponent } from './pages/news/news.component';
import { EventiComponent } from './pages/eventi/eventi.component';
import { SingleNewsComponent } from './pages/single-news/single-news.component';
import { SingleEventiComponent } from './pages/single-eventi/single-eventi.component';
import { GlossarioPageComponent } from './pages/glossario-page/glossario-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'chi_siamo/sponsor',
    component: SponsorPageComponent
  },
  {
    path: 'informazioni/news',
    component: NewsComponent
  },
  {
    path: 'informazioni/news/:id',
    component: SingleNewsComponent
  },
  {
    path: 'informazioni/eventi',
    component: EventiComponent
  },
  {
    path: 'informazioni/eventi/:id',
    component: SingleEventiComponent
  },
  {
    path: 'informazioni/glossario',
    component: GlossarioPageComponent
  },
  {
    path: '**',
    component: PageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
