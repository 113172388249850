import { Component, OnInit } from '@angular/core';
import { SponsorService } from 'src/app/services/sponsor.service';
import { Sponsor } from 'src/app/models/Sponsor';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})
export class SponsorComponent implements OnInit {

  sponsorSostenitore:Sponsor[];
  sponsorSostenitoreHigher:Sponsor[];

  constructor(private sponsorService:SponsorService) { }

  ngOnInit() {
    this.sponsorService.getSponsorsSostenitore().subscribe(data => {
      this.sponsorSostenitore = data;
    })

    this.sponsorService.getSponsorsSostenitoreHigher().subscribe(data => {
      this.sponsorSostenitoreHigher = data;
    })
  }

  getPath(sponsor:Sponsor):string {
    let path = sponsor.logo.url;
    path = path.replace(/\/uploads\//g, environment.baseUrl + "uploads/");
    return path;
  }

  getStyle(sponsor:Sponsor):string {
    let style = "";

    if (sponsor.logoWidth != null) {
      style += "width: " + sponsor.logoWidth + "px;";
    }

    if (sponsor.logoHeight != null) {
      style += "height: " + sponsor.logoHeight + "px;";
    }

    return style;
  }
}
