import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/services/pages.service';
import { MarkdownComponent } from 'ngx-markdown';
import { Page } from 'src/app/models/Page';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  page:Page;

  constructor(private pageService:PagesService) { }

  ngOnInit() {
    this.pageService.getPage(22).subscribe(async data => {
      this.page = data;
    })
  }

  markdownReady(object:MarkdownComponent) {
    let html = object.element.nativeElement.innerHTML;
    html = html.replace(/\<a /g, "<a target=\"_blank\" ");
    html = html.replace(/\/uploads\//g, environment.baseUrl + "uploads/")
    object.element.nativeElement.innerHTML = html;
  }

}
