import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from 'src/app/services/pages.service';
import { Page } from 'src/app/models/Page';
import { async } from '@angular/core/testing';
import { MarkdownComponent } from 'ngx-markdown';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  
  page:Page;
  three:Page[];
  bg:string;
  
  constructor(private route:ActivatedRoute, private pageService:PagesService) { }
  
  ngOnInit() {
    this.route.url.subscribe(paths => {
      this.pageService.getPageByUrl(paths).subscribe(async data => {
        
        if (data.redirectLink != null) {
          window.location.href = data.redirectLink;
        }

        this.page = data;
        let three:Page[] = [];
        let page:Page = data;
        while (page.parentPage != null) {
          page = await this.pageService.getPage(page.parentPage.id).toPromise();
          data = page;
          three[three.length] = page;
        };
        
        three.reverse();
        this.three = three;

        this.bg = this.page.titleLink;
        if (three != null) {
          this.bg = three[0].titleLink;
        }

        let url:string = '';
        this.three.forEach(t => {
          url += t.titleLink + '/';
          t.titleLink = url;
        })

        this.page.titleLink = url + this.page.titleLink;
      });
    })
  } 

  markdownReady(object:MarkdownComponent) {
    let html = object.element.nativeElement.innerHTML;
    html = html.replace(/\<a /g, "<a target=\"_blank\" ");
    html = html.replace(/\/uploads\//g, environment.baseUrl + "uploads/")
    object.element.nativeElement.innerHTML = html;
  }
}
