import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { News } from '../models/News';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'appliaction/json'
  })
}

const orderByNewset = "?_sort=date:desc";
const orderByOldest = "?_sort=date:asc";

const newsFilter = "&date_lt=";
const eventFilter = "&date_gt=";

const now = Date.now();

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  pageUrl:string = environment.baseUrl + 'newsses';

  constructor(private http:HttpClient) { }

  getNews():Observable<News[]> {
    const url = this.pageUrl + orderByNewset + newsFilter + now;
    return this.http.get<News[]>(url, httpOptions);
  }

  getEvents():Observable<News[]> {
    const url = this.pageUrl + orderByOldest + eventFilter + now;
    return this.http.get<News[]>(url, httpOptions);
  }

  getNewsById(id:number):Observable<News> {
    const url = this.pageUrl + '/' + id;
    return this.http.get<News>(url, httpOptions);
  }
}
