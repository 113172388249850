import { Component, OnInit } from '@angular/core';

import { SubscribeNewsletterService } from 'src/app/services/subscribe-newsletter.service';

const emailTest = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

@Component({
  selector: 'app-subscribe-newsletter',
  templateUrl: './subscribe-newsletter.component.html',
  styleUrls: ['./subscribe-newsletter.component.css']
})
export class SubscribeNewsletterComponent implements OnInit {

  firstname: string;
  lastname: string;
  email: string;

  constructor(private subscribeNewsletterService: SubscribeNewsletterService) { }

  ngOnInit() {
  }


  onSubmit() {
    let error = false;
    if (this.firstname.length === 0) {
      alert('missing firstname');
      error = true;
    }
    if (this.lastname.length === 0) {
      alert('missing lastname');
      error = true;
    }
    if (!emailTest.test(String(this.email).toLowerCase())) {
      alert('not valid email');
      error = true;
    }

    if (error) {
      return;
    }

    const s = {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
    };

    this.subscribeNewsletterService.subscribeNewsletter(s).subscribe(r => {
      console.log(r);
    });
  }
}
