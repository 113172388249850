import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Contact } from '../models/Contact';
import { SubscribeNewsletter } from '../models/SubscribeNewsletter';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class SubscribeNewsletterService {

  url: string = environment.emailUrl;

  constructor(private http: HttpClient) { }

  subscribeNewsletter(s: SubscribeNewsletter): Observable<any> {
    const c = new Contact();
    c.from = 'info@cassi.ch';
    c.subject = 'Newsletter subscription';
    c.message = 'Firstname: ' + s.firstname + '\nLastname: ' + s.lastname + '\nEmail: ' + s.email + '\nDate: ' + Date.now();

    return this.http.post(this.url, c, httpOptions);
  }
}
