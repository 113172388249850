import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { PagesService } from 'src/app/services/pages.service';
import { Page } from 'src/app/models/Page';
import { NavService } from 'src/app/service/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  pages:Page[] = [];
  activePath:string;

  constructor(private location:Location, private pageService:PagesService, private navService:NavService) { }

  ngOnInit() {
    this.activePath = this.location.path().split('/')[1];

    this.pageService.getPages().subscribe(pages => {
      pages.forEach(page => {
        if (page.parentPage == null && page.titleLink != '/') {
          this.pages[this.pages.length] = page;
        }
      }) 
    })
  }

  toggleNav() {
    this.navService.toggle();
  }
}
