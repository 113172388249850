import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Sponsor } from '../models/Sponsor';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

const sponsorSostenitore:string = '?type=sostenitore';
const sponsorSostenitoreHigher:string = '?type=sostenitore_';
const orderByName:string = "?_sort=name:asc";

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  pageUrl:string = environment.baseUrl + 'sponsors';

  constructor(private http:HttpClient) { }

  getSponsors():Observable<Sponsor[]> {
    const url = this.pageUrl + orderByName;
    return this.http.get<Sponsor[]>(url, httpOptions);
  }

  getSponsor(id:number):Observable<Sponsor> {
    const url = this.pageUrl + '/' + id + orderByName;
    return this.http.get<Sponsor>(url, httpOptions);
  }

  getSponsorsSostenitore():Observable<Sponsor[]> {
    const url = this.pageUrl + sponsorSostenitore + '&_sort=name:asc';
    return this.http.get<Sponsor[]>(url, httpOptions);
  }

  getSponsorsSostenitoreHigher():Observable<Sponsor[]> {
    const url = this.pageUrl + sponsorSostenitoreHigher + '&_sort=name:asc';
    return this.http.get<Sponsor[]>(url, httpOptions);
  }
}
