export default class DateUtils {

    static renderDate(date:Date, dateType:string):string {
        date = new Date(date);
        switch(dateType) {
        case 'YYYY':
            return DateUtils.renderDateYear(date);
        case 'MMYYYY':
            return DateUtils.renderDateMonth(date);
        case 'DDMMYYYY':
            return DateUtils.renderDateDay(date);
        case 'DDMMYYYYhhmm':
            return DateUtils.renderDateTime(date);
        default:
            return date.toString();
        }
    }

    private static renderDateYear(date:Date):string {
        return date.getFullYear().toString();
    }

    private static renderDateMonth(date:Date):string {
        let month:string = (date.getMonth() + 1).toString();
        month = DateUtils.normalize(month);

        return DateUtils.concatenate(month, DateUtils.renderDateYear(date), '.')
    }

    private static renderDateDay(date:Date):string {
        let day:string = date.getDate().toString();
        day = DateUtils.normalize(day);

        return DateUtils.concatenate(day, DateUtils.renderDateMonth(date), '.')
    }

    private static renderDateTime(date:Date):string {
        let hours:string = date.getHours().toString();
        hours = DateUtils.normalize(hours);

        let minutes: string = date.getMinutes().toString();
        minutes = DateUtils.normalize(minutes);

        let time = DateUtils.concatenate(hours, minutes, ':');

        return DateUtils.concatenate(DateUtils.renderDateDay(date), time, ', ');
    }

    private static normalize(data:string):string {
        return data.length>1?data:'0'+data;
    }

    private static concatenate(a:string, b:string, c:string):string {
        return a + c + b;
    }
}