import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PagesService } from 'src/app/services/pages.service';
import { Page } from 'src/app/models/Page';
import { NavService } from 'src/app/service/nav.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  activePath:string[];
  pages:Page[] = [];
  open:boolean = false;

  constructor(private location:Location, private pagesService:PagesService, private navService:NavService) { }

  ngOnInit() {
    this.activePath = this.location.path().split('/');

    this.pagesService.getPages().subscribe(async (pages) => {
      await pages.forEach(async (page) => {
        if (page.parentPage == null && page.titleLink != '/') {
          await page.childPages.sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });
          this.pages[this.pages.length] = page;
        }
      })
    })

    this.navService.sharedOpen.subscribe(open => {
      this.open = open;
    })
  }

}
