import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private open = new BehaviorSubject(false);
  sharedOpen = this.open.asObservable();

  constructor() { }

  toggle() {
    this.open.next(!this.open.getValue());
  }
}
