import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/services/news.service';
import { News } from 'src/app/models/News';
import { MarkdownComponent } from 'ngx-markdown';

import { environment } from 'src/environments/environment';
import DateUtils from 'src/app/utils/DateUtils';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newses:News[];
  showme:boolean[] = [];

  constructor(private newsService:NewsService) { }

  ngOnInit() {
    this.newsService.getNews().subscribe(newses => {
      for (let i = 0; i < newses.length; i++) {
        this.showme[i] = false;
      }
      this.newses = newses;

      if (this.showme.length > 0) {
        this.showme[0] = true;
      }
    })
  }

  markdownReady(object:MarkdownComponent) {
    console.log('hey')
    let html = object.element.nativeElement.innerHTML;
    html = html.replace(/\<a /g, "<a target=\"_blank\" ");
    html = html.replace(/\/uploads\//g, environment.baseUrl + "uploads/")
    object.element.nativeElement.innerHTML = html;
  }

  click(i:number):void {
    if (this.showme[i]) {
      this.showme[i] = false;
    } else {
      for (let j = 0; j < this.showme.length; j++) {
        this.showme[j] = false;
      }

      this.showme[i] = true;
    }
  }

  date(news:News):string {
    return DateUtils.renderDate(news.date, news.dateType);
  }

  facebook(id:number) {
    window.open("http://www.facebook.com/sharer.php?u=https%3A%2F%2Fcassi.ch%2Finformazioni%2Fnews%2F/"+id,"sharer","toolbar=0,status=0,width=548,height=325")
  }

  twitter(news:News) {
    window.open("https://twitter.com/intent/tweet?text="+ news.title + "&url=https://cassi.ch/informazioni/news/"+ news.id,"sharer","toolbar=0,status=0,width=548,height=325")
  }
}
