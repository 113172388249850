import { Component, OnInit } from '@angular/core';
import { Glossario } from 'src/app/models/Glossario';
import { Page } from 'src/app/models/Page';
import { ActivatedRoute } from '@angular/router';
import { GlossarioService } from 'src/app/services/glossario.service';
import { PagesService } from 'src/app/services/pages.service';
import { MarkdownComponent } from 'ngx-markdown';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-glossario-page',
  templateUrl: './glossario-page.component.html',
  styleUrls: ['./glossario-page.component.css']
})
export class GlossarioPageComponent implements OnInit {

  glossario:Glossario[];
  page:Page;
  showme=[];

  constructor(private route:ActivatedRoute, private glossarioService:GlossarioService, private pageService:PagesService) { }

  ngOnInit() {
    this.glossarioService.getGlossario().subscribe(glossario => {
      this.glossario = glossario;

      for (let i = 0; i < glossario.length; i++) {
        this.showme[i] = false;
      }
      this.showme[this.showme.length] = false;
      this.showme[0] = true;
    })

    this.route.url.subscribe(paths => {
      this.pageService.getPageByUrl(paths).subscribe(async data => {
        if (data.redirectLink != null) {
          window.location.href = data.redirectLink;
        }

        this.page = data;
      })
    })
  }

  markdownReady(object:MarkdownComponent) {
    let html = object.element.nativeElement.innerHTML;
    html = html.replace(/\<a /g, "<a target=\"_blank\" ");
    html = html.replace(/\/uploads\//g, environment.baseUrl + "uploads/")
    object.element.nativeElement.innerHTML = html;
  }

  click(i:number):void {
    if (this.showme[i]) {
      this.showme[i] = false;
    } else {
      for (let j = 0; j < this.showme.length; j++) {
        this.showme[j] = false;
      }

      this.showme[i] = true;
    }
  }

}
