import { NumberSymbol } from "@angular/common";

export class Page {
    id:number;
    title:string;
    titleLink:string;
    content:string;
    childPages:Page[];
    parentPage:Page;
    redirectLink:string;
}