import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Page } from '../models/Page';
import { UrlSegment } from '@angular/router';

import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

const orderById:string = '?_sort=id:asc';
const orderByTitle:string = '?_sort=title:asc';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  pageUrl:string = environment.baseUrl + 'pages';

  constructor(private http:HttpClient) { }

  getPages():Observable<Page[]> {
    const url = this.pageUrl + orderById;
    return this.http.get<Page[]>(url, httpOptions);
  }

  getPage(id:number):Observable<Page> {
    const url = this.pageUrl + '/' + id + orderById;
    return this.http.get<Page>(url, httpOptions);
  }

  // TODO fix observable
  getPageByUrl(paths:UrlSegment[]):Observable<Page> {
    let pathString = [];

    paths.forEach(path => {
      pathString[pathString.length] = path.path;
    });

    return this.getPages().pipe(map(pages => {
      let page:Page = null;

      for (let i = 0; i < paths.length; i++) {
        for (let j = 0; j < pages.length; j++) {
          if (paths[i].path == pages[j].titleLink) {
            if (i == paths.length - 1) {
              page = pages[j];
              i = paths.length;
              j = pages.length;
            } 
          }
        }
      } 

      return page;
    }));
  }
}
