import { Component, OnInit } from '@angular/core';
import { SponsorService } from 'src/app/services/sponsor.service';
import { Sponsor } from 'src/app/models/Sponsor';
import { Page } from 'src/app/models/Page';
import { PagesService } from 'src/app/services/pages.service';
import { ActivatedRoute } from '@angular/router';
import { MarkdownComponent } from 'ngx-markdown';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sponsor-page',
  templateUrl: './sponsor-page.component.html',
  styleUrls: ['./sponsor-page.component.css']
})
export class SponsorPageComponent implements OnInit {

  sponsors:Sponsor[];
  page:Page;
  three:Page[];
  bg:string;
  showme=[];

  constructor(private route:ActivatedRoute, private sponsorService:SponsorService, private pageService:PagesService) { }

  ngOnInit() {
    console.log('h1y')
    this.sponsorService.getSponsors().subscribe(sponsors => {
      this.sponsors = sponsors;
      for (let i = 0; i < sponsors.length; i++) {
        this.showme[i] = false;
      }
      this.showme[this.showme.length] = false;
      this.showme[0] = true;
    })

    this.route.url.subscribe(paths => {
      this.pageService.getPageByUrl(paths).subscribe(async data => {
        
        if (data.redirectLink != null) {
          window.location.href = data.redirectLink;
        }

        this.page = data;
        let three:Page[] = [];
        let page:Page = data;
        while (page.parentPage != null) {
          page = await this.pageService.getPage(page.parentPage.id).toPromise();
          data = page;
          three[three.length] = page;
        };
        
        three.reverse();
        this.three = three;

        this.bg = this.page.titleLink;
        if (three != null) {
          this.bg = three[0].titleLink;
        }

        let url:string = '';
        this.three.forEach(t => {
          url += t.titleLink + '/';
          t.titleLink = url;
        })

        this.page.titleLink = url + this.page.titleLink;
      });
    })
  }

  markdownReady(object:MarkdownComponent) {
    let html = object.element.nativeElement.innerHTML;
    html = html.replace(/\<a /g, "<a target=\"_blank\" ");
    html = html.replace(/\/uploads\//g, environment.baseUrl + "uploads/")
    object.element.nativeElement.innerHTML = html;
  }

  getLogoPath(sponsor:Sponsor):string {
    let path = sponsor.logo.url;

    path = path.replace(/\/uploads\//g, environment.baseUrl + "uploads/");

    return path;
  }

  click(i:number):void {
    if (this.showme[i]) {
      this.showme[i] = false;
    } else {
      for (let j = 0; j < this.showme.length; j++) {
        this.showme[j] = false;
      }

      this.showme[i] = true;
    }
  }
}
