import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { MarkdownModule } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { FooterComponent } from './components/footer/footer/footer.component';
import { SponsorComponent } from './components/footer/sponsor/sponsor.component';
import { HeaderComponent } from './components/header/header/header.component';
import { NavComponent } from './components/nav/nav/nav.component';
import { NewsComponent } from './pages/news/news.component';
import { EventiComponent } from './pages/eventi/eventi.component';
import { ContattiComponent } from './pages/contatti/contatti.component';
import { PageComponent } from './pages/page/page.component';
import { NavItemComponent } from './components/nav/nav-item/nav-item.component';
import { SponsorPageComponent } from './pages/sponsor-page/sponsor-page.component';
import { SingleNewsComponent } from './pages/single-news/single-news.component';
import { SingleEventiComponent } from './pages/single-eventi/single-eventi.component';
import { GlossarioPageComponent } from './pages/glossario-page/glossario-page.component';
import { SubscribeNewsletterComponent } from './components/subscribe-newsletter/subscribe-newsletter.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    FooterComponent,
    SponsorComponent,
    HeaderComponent,
    NavComponent,
    NewsComponent,
    EventiComponent,
    ContattiComponent,
    PageComponent,
    NavItemComponent,
    SponsorPageComponent,
    SingleNewsComponent,
    SingleEventiComponent,
    GlossarioPageComponent,
    SubscribeNewsletterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MarkdownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
