import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Page } from 'src/app/models/Page';
import { PagesService } from 'src/app/services/pages.service';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.css']
})
export class NavItemComponent implements OnInit {

  selected:boolean = false;
  @Input() page:Page;
  @Input() fatherLink:string = '';
  @Input() noChild:boolean = false;

  constructor(private location:Location, private pageService:PagesService) { }

  ngOnInit() {
    if (this.page.childPages == null) {
      this.pageService.getPage(this.page.id).subscribe(async (page) => {
        if (page.childPages != null) {
          page.childPages = await page.childPages.sort((a, b) => {
            if ( a.title < b.title ) {
              return -1;
            }
            if ( a.title > b.title ) {
              return 1;
            }
            return 0;
          });
        }
        this.page = page;
      });
    }

    let path = this.fatherLink.split('/');
    
    let location = this.location.path().split('/');
    
    for (let i = 0; i < location.length; i++) {
      if (path.length == i && this.page.titleLink == location[i]) {
        this.selected = true;
      }
    }

    if (location[location.length -1] == this.page.titleLink) {
      this.selected = true;
    }

  }
}
