import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from 'src/app/services/news.service';
import { News } from 'src/app/models/News';
import DateUtils from 'src/app/utils/DateUtils';
import { MarkdownComponent } from 'ngx-markdown';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-eventi',
  templateUrl: './single-eventi.component.html',
  styleUrls: ['./single-eventi.component.css']
})
export class SingleEventiComponent implements OnInit {

  news:News;

  constructor(private route:ActivatedRoute, private newsService:NewsService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = +params['id'];
      this.newsService.getNewsById(id).subscribe(news => {
        this.news = news;
      })
    })
  }

  markdownReady(object:MarkdownComponent) {
    console.log('hey')
    let html = object.element.nativeElement.innerHTML;
    html = html.replace(/\<a /g, "<a target=\"_blank\" ");
    html = html.replace(/\/uploads\//g, environment.baseUrl + "uploads/")
    object.element.nativeElement.innerHTML = html;
  }

  date(news:News):string {
    return DateUtils.renderDate(news.date, news.dateType);
  }

  facebook(id:number) {
    window.open("http://www.facebook.com/sharer.php?u=https%3A%2F%2Fcassi.ch%2Finformazioni%2Feventi%2F/"+id,"sharer","toolbar=0,status=0,width=548,height=325")
  }

  twitter(news:News) {
    window.open("https://twitter.com/intent/tweet?text="+ news.title + "&url=https://cassi.ch/informazioni/eventi/"+ news.id,"sharer","toolbar=0,status=0,width=548,height=325")
  }

}
